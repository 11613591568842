import React, { useState } from 'react';
import { check_pass_criteria, update_password, validate_pass } from './action';
import { errors, input_types } from './conts';
const logo = "https://dacq68pa0iusn.cloudfront.net/filters:format(webp)/USFlagStore/WebGraphics/Homepage/header-logo.png";

let new_pass, cf_pass;
const Form = ({ setIsSuccess }) => {
  const [err, setErr] = useState(null);
  const [inputType, setType] = useState(input_types.password);
  const [isLoading, setLoading] = useState(false);
  const togglePassType = (e) => setType(e.target.checked ? input_types.text : input_types.password);
  const onPassChage = (e) => new_pass = e.target.value;

  const updatePassword = async () => {
    try {
      if (validate_pass(new_pass, cf_pass, setErr)) {
        setLoading(true);
        await update_password(new_pass);
        setIsSuccess(true);
      }
    } catch (err) {
      setErr(err.message || errors.update_err);
      setLoading(false);
    }
  }

  const onPassBlur = (e) => {
    setErr(null);
    new_pass = e.target.value;
    check_pass_criteria(new_pass, setErr);
  }

  const onCPassChage = (e) => { setErr(null); cf_pass = e.target.value; }

  return (<div className="pass-upate-form">
    <div className="logo-wrapper"><a href="https://www.usflagstore.com"><img src={logo} /></a></div>
    <h1>Reset Password</h1>
    <div className="password-form-container">
      <div className="grp">
        <label htmlFor="ps">New Password</label>
        <input name="ps" className="form-control" placeholder="New password" type={inputType} onChange={onPassChage} onBlur={onPassBlur} />
      </div>
      <div className="grp">
        <label htmlFor="cf" className="grp">Confirm Password</label>
        <input name="cf" className="form-control" placeholder="Confirm password" type={inputType} onChange={onCPassChage} onBlur={onCPassChage} />
      </div>
      <div className="check-grp">
        <label style={{ width: "120px" }}>Show Password</label>
        <input style={{ width: "20px", height: "15px" }} type="checkbox" className="form-control" onChange={togglePassType} />
      </div>

      <div className="btn-err-wrapper">
        {<button onClick={updatePassword} disabled={err || isLoading}>{isLoading ? "In progress..." : "Reset Password"}</button>}
        {<div className="err">{err} </div>}
      </div>

      <div className="login-signup-wrapper">
        <a href="https://www.usflagstore.com/">Already have an account? </a>
        <a href="https://www.usflagstore.com/signup">Create a new account </a>
      </div>
    </div>
  </div>)
}

export default Form;
